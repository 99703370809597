import { checkoutFillRequiredUserFields } from './checkout.js';
import { openModal, closeModal } from './modal.js';
import { showProductButton } from './single-product.js';
import { rudrAddToCart } from './add-to-cart.js';
import { getCookie } from './getCookie.js';
import $ from 'jquery';

const ajax_url = window?.sage?.ajax_url;
const nonce = window?.sage?.nonce;
const checkoutURL = window?.sage?.checkoutURL;

export const openModalBooking = (e) => {
  const storeID = getCookie('storeID');
  if (!storeID) {
    $('.open-modal-select-store').trigger('click');

    return;
  }

  const dataAddToCart = $('#data-add-to-cart')?.val();
  // const title = "Schedule an appointment";
  var title = $('#data-booking-title').val();
  const productType = $('#data-product-type').val();
  var hasAddToCart = false;

  if (dataAddToCart) {
    hasAddToCart = dataAddToCart;
  }

  if (!title) {
    title = 'Schedule your collection time';
  }

  openModal(title);

  $.ajax({
    type: 'post',
    url: ajax_url,
    data: {
      action: 'get_book_appointment_modal',
      // nonce: nonce,
      productType: productType,
      storeID: storeID,
    },
    success: function (response) {
      if (response.success) {
        $('#modal-container-content').html(response.data.html);
        $('#add-to-cart-handler').val(hasAddToCart);
      }
    },
  });
};

const addToCart = (productId) => {
  openModal('Loading');
  rudrAddToCart(productId, 1)
    .then((response) => {
      window.location.href = checkoutURL;
    })
    .catch((error) => {
      // Handle error
      console.error('Error:', error);
    });
};

$(() => {
  const $userAppointmentDateTime = $('.user-appointment-date-time');
  const $userAppointmentWeekDay = $('.user-appointment-week-day');

  const setBookingDateCookie = (date, time, weekDay) => {
    $userAppointmentDateTime.html(skeleton);
    $userAppointmentWeekDay.html(skeleton);

    const dateCookie = `bookingDate=${date}; expires=${new Date(
      Date.now() + 3 * 24 * 60 * 60 * 1000
    ).toUTCString()}; path=/`;
    const timeCookie = `bookingTime=${time}; expires=${new Date(
      Date.now() + 3 * 24 * 60 * 60 * 1000
    ).toUTCString()}; path=/`;

    document.cookie = dateCookie;
    document.cookie = timeCookie;

    setTimeout(() => {
      $userAppointmentDateTime.html(`${date} at ${time}`);
      $userAppointmentWeekDay.html(weekDay);
    }, 1000);
  };

  const skeleton = `<span class="block animate-pulse bg-slate-200 h-4 rounded "></span>`;

  $(document).on('click', '#book-appointment-apply', () => {
    const date = $('#book-appointment-date').val();
    const time = $('#book-appointment-time').val();
    const weekDay = $('#book-appointment-week-day').val();

    setBookingDateCookie(date, time, weekDay);

    showProductButton();
    checkoutFillRequiredUserFields();

    if (
      $('#add-to-cart-handler').val() &&
      $('#add-to-cart-handler').val() != '' &&
      $('#add-to-cart-handler').val() != 'false'
    ) {
      // addToCart($("#add-to-cart-handler").val());
      $('#master-btn-add-to-cart').trigger('click');
    }

    if ($('#data-ajax-add-to-cart')?.val()) {
      addToCart($('#data-ajax-add-to-cart').val());
    }

    closeModal();
  });

  $('.open-modal-book-appointment').on('click', openModalBooking);

  $('body').on('click', '#book-appointment-cancel', () => {
    document.cookie =
      'bookingDate=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie =
      'bookingTime=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    setTimeout(() => {
      window.location.reload();
    }, 500);
  });
});

export const checkIfitIsReadyToApply = () => {
  const date = $('#book-appointment-date').val();
  const time = $('#book-appointment-time').val();

  if (date && time) {
    $('#book-appointment-apply').removeAttr('disabled');
  }
};

$(document).on('change', '.book-appointment-time', checkIfitIsReadyToApply);
