import localforage from 'localforage';
import $ from 'jquery';

export const localForageDB = () => {
    // Configure localForage
    localforage.config({
        name: 'ProductDatabase',
        storeName: 'productsStore',
        description: 'Storage for product data',
    });

    // Optional: Set driver to IndexedDB explicitly
    localforage.setDriver(localforage.INDEXEDDB).catch(function (e) {
        console.error('Failed to set driver to IndexedDB:', e);
    });
};

export async function checkAndLoadProducts(shopType, callback) {
    try {
        const versionKey = 'products_cache_version';
        const cachedVersion = await localforage.getItem(versionKey);
        const currentVersion = window.sage.productsCacheVersion;

        const expiryKey = `products_for_shop_expiry_${shopType}`;
        const currentTime = new Date().getTime();
        const resetTime = new Date();
        resetTime.setHours(2, 0, 0, 0);

        if (currentTime >= resetTime.getTime()) {
            resetTime.setDate(resetTime.getDate() + 1);
        }

        const expiryTime = await localforage.getItem(expiryKey);

        if (
            cachedVersion !== currentVersion ||
            !expiryTime ||
            currentTime >= expiryTime
        ) {
            // Clear old data
            await localforage.removeItem(`products_for_shop_${shopType}`);
            await localforage.setItem(versionKey, currentVersion);
            await localforage.setItem(expiryKey, resetTime.getTime());
        }

        // Try to get products from localForage
        const cachedProducts = await localforage.getItem(
            `products_for_shop_${shopType}`
        );

        if (cachedProducts) {
            // Products are available in localForage
            callback(cachedProducts);
        } else {
            // Fetch products from the server
            $.ajax({
                type: 'post',
                url:
                    typeof window !== 'undefined' && window.sage
                        ? window.sage.ajax_url
                        : 'https://advantagetyres.co.nz/wp/wp-admin/admin-ajax.php',
                data: {
                    action: 'get_products_for_shop',
                    shop_type: shopType,
                },
                success: async function (response) {
                    let products = response.data.products;
                    try {
                        // Store products in localForage
                        await localforage.setItem(
                            `products_for_shop_${shopType}`,
                            products
                        );

                        // Update the cache version and expiry time
                        await localforage.setItem(versionKey, currentVersion);
                        await localforage.setItem(
                            expiryKey,
                            resetTime.getTime()
                        );
                    } catch (e) {
                        if (e && e.name === 'QuotaExceededError') {
                            console.warn('Storage full. Clearing old data.');
                            await localforage.clear(); // Clear old data
                            await localforage.setItem(
                                `products_for_shop_${shopType}`,
                                products
                            ); // Try again
                        } else {
                            console.error(
                                'Failed to store products in localForage:',
                                e
                            );
                        }
                    }

                    callback(products);
                },
                error: function (error) {
                    console.log('Error fetching products from server:', error);
                },
            });
        }
    } catch (error) {
        console.error('An error occurred:', error);
        // Optionally, fetch data from the server as a fallback
        $.ajax({
            type: 'post',
            url: window?.sage?.ajax_url,
            data: {
                action: 'get_products_for_shop',
                shop_type: shopType,
            },
            success: function (response) {
                const products = response.data.products;
                callback(products);
            },
            error: function (error) {
                console.log('Error fetching products from server:', error);
            },
        });
    }
}
